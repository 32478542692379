import ApplicationController from '../application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  add_association(event) {
    event.preventDefault();
    const { target } = event.currentTarget.dataset;
    const targetElement = document.querySelector(`template.${target}`);
    const content = targetElement.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    document.getElementById(`${target}-adjustments`).insertAdjacentHTML('beforebegin', content);
  }

  remove_association(event) {
    event.preventDefault();
    const item = event.target.closest('.nested-fields');
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = 'none';
  }
}
