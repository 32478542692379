import ApplicationController from '../../application_controller';
import { scrollToElement } from '../../utilities/scrolling';

export default class extends ApplicationController {
  static targets = [
    'address1',
    'address2',
    'city',
    'stateId',
    'zipcode',
    'form',
    'submitBtn',
    'addressToggle',
  ];

  validStates = this.stateIdTarget.dataset.validStates;

  connect() {
    super.connect();
    this.addStoredFieldValues();
    document.addEventListener('store-checkout-address-rewire', this.rewire.bind(this));
    document.addEventListener('store-checkout-address-validation-bypass', this.bypassValidation.bind(this));
    document.addEventListener('store-checkout-address-validation-enableSubmit', this.enableSubmit.bind(this));
    document.addEventListener('store-checkout-address-validation-display-address-errors', this.displayAddressErrors.bind(this));
    this.addressToggleTarget.addEventListener('change', this.loadAddressForm.bind(this));
    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('store-checkout-address-rewire', this.rewire.bind(this));
    document.removeEventListener('store-checkout-address-validation-bypass', this.bypassValidation.bind(this));
    document.removeEventListener('store-checkout-address-validation-enableSubmit', this.enableSubmit.bind(this));
    document.removeEventListener('store-checkout-address-validation-display-address-errors', this.displayAddressErrors.bind(this));
    this.addressToggleTarget.removeEventListener('change', this.loadAddressForm.bind(this));
    this.removeInputEventListeners();
    delete window.storeCheckoutSkipAddressLookup;
    delete window.storeCheckoutValidatedCurrentValues;
  }

  rewire() {
    this.removeInputEventListeners();
    this.addInputEventListeners();
    this.toggleButtonEnabled();
  }

  submitForm(event) {
    if (event) event.preventDefault();

    if (!this.addressValuesPresent() || window.storeCheckoutSkipAddressLookup) {
      this.formTarget.removeAttribute('data-action');
      this.formTarget.submit();
    } else {
      this.removeBypassedHiddenField();
      this.validateAddress();
      window.storeCheckoutValidatedCurrentValues = true;
      this.address1Target.parentNode.querySelector('p.text-error').innerHTML = '';
    }
  }

  addressValuesPresent() {
    return this.address1Target.value &&
      this.cityTarget.value &&
      this.stateIdTarget.value &&
      this.zipcodeTarget.value;
  }

  validateAddress() {
    this.callStimulusAction({
      cb: () => {
        this.stimulate(
          'Store::Cart::CheckoutAddressReflex#validate',
          this.address1Target.value,
          this.address2Target.value,
          this.cityTarget.value,
          this.stateIdTarget.value,
          this.zipcodeTarget.value,
          window.storeCheckoutValidatedCurrentValues || false,
        );
      },
    });
  }

  loadAddressForm(event) {
    event && event.preventDefault();
    this.removeInputEventListeners();
    const checked = event.currentTarget.checked
    this.callStimulusAction({
      cb: () => {
        this.stimulate('Store::Cart::CheckoutAddressReflex#toggle_address', null, checked)
      }
    });
    this.addInputEventListeners();
  }

  bypassValidation(event) {
    const { logSkip } = event.detail;
    if (logSkip) this.addBypassedHiddenField();
    window.storeCheckoutSkipAddressLookup = true;
    this.submitForm();
  }

  addBypassedHiddenField() {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'checkout_form[skip_address_lookup]');
    input.setAttribute('value', 'true');
    input.setAttribute('id', 'skip_address_lookup_field');
    this.formTarget.appendChild(input);
  }

  removeBypassedHiddenField() {
    const hiddenField = document.getElementById('skip_address_lookup_field');
    if (hiddenField) hiddenField.remove();
  }

  enableSubmit() {
    scrollToElement(this.address1Target.parentNode);
    this.toggleButtonEnabled();
  }

  clearRepeatAddressValidation() {
    window.storeCheckoutValidatedCurrentValues = false;
    this.toggleButtonEnabled();
  }

  formInputs() {
    return Array.from(this.formTarget.querySelectorAll('input[type=text]:not(#store_checkout_form_ship_address_attributes_address2, #bill_address_attributes_address2), option:checked, select'));
  }

  toggleButtonEnabled() {
    if (this.formInputs().every((input) => input.value) && this.serviceableStateSelected()) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  enableSubmitButton() {
    this.submitBtnTarget.removeAttribute('disabled');
    this.submitBtnTarget.classList.remove('cursor-not-allowed');
    this.submitBtnTarget.classList.add('!bg-rugietmustard', 'hover:!bg-rugietmustardhover');
  }

  disableSubmitButton() {
    this.submitBtnTarget.setAttribute('disabled', 'disabled');
    this.submitBtnTarget.classList.add('cursor-not-allowed');
    this.submitBtnTarget.classList.remove('!bg-rugietmustard', 'hover:!bg-rugietmustardhover');
  }

  addInputEventListeners() {
    this.stateIdTarget.addEventListener('change', this.validateServiceableState.bind(this));
    this.formInputs().forEach((input) => {
      input.addEventListener('change', this.toggleButtonEnabled.bind(this));
      input.addEventListener('input', this.toggleButtonEnabled.bind(this));
    });
  }

  removeInputEventListeners() {
    this.formInputs().forEach((input) => {
      input.removeEventListener('change', this.toggleButtonEnabled.bind(this));
      input.removeEventListener('input', this.toggleButtonEnabled.bind(this));
    });
    this.stateIdTarget.removeEventListener('change', this.validateServiceableState.bind(this));
  }

  storeFieldValues(event) {
    const element = event.currentTarget;
    localStorage.setItem(element.id, element.value);
  }

  addStoredFieldValues() {
    this.formFieldsForLocalstorage().forEach((target) => {
      const value = localStorage.getItem(target.id);
      /* eslint no-param-reassign: ["error", { "props": false }] */
      if (value) target.value = value;
    });
  }

  formFieldsForLocalstorage() {
    return [
      this.address1Target,
      this.address2Target,
      this.cityTarget,
      this.zipcodeTarget,
      this.stateIdTarget,
    ];
  }

  displayAddressErrors(event) {
    const parentOfFieldElement = this.address1Target.parentNode;
    const { errorMessage } = event.detail;

    scrollToElement(parentOfFieldElement);
    parentOfFieldElement.querySelector('p.text-error').innerHTML = errorMessage;
  }

  validateServiceableState() {
    if (this.serviceableStateSelected()) {
      this.toggleButtonEnabled();
    } else {
      this.disableSubmitButton();

      this.callStimulusAction({
        cb: () => {
          this.stimulate('Store::Cart::CheckoutAddress#unserviceable_state');
        }
      });
    }
  }

  serviceableStateSelected() {
    if (!this.stateIdTarget.value) return false;

    const validStates = this.stateIdTarget.dataset.validStates;
    return validStates.includes(this.stateIdTarget.value);
  }
}
