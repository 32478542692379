import moment from 'moment';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['dob', 'phoneNum', 'state', 'submit', 'arrow'];

  validStates = this.stateTarget.dataset.validStates;

  /* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
  afterValidatePhoneNumber() {
    this.phoneNumTarget.getAttribute('valid') === 'true' ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  connect() {
    super.connect();
    this.addEventListeners();
    this.disableSubmitButton();
  }

  disconnect() {
    super.disconnect();
    this.removeEventListeners();
  }

  addEventListeners() {
    this.dobHandler = this.validateDob.bind(this);
    this.phoneNumHandler = this.validatePhoneNumber.bind(this);
    this.stateHandler = this.validateState.bind(this);

    this.dobTarget.addEventListener('blur', this.dobHandler);
    this.phoneNumTarget.addEventListener('change', this.phoneNumHandler);
    this.phoneNumTarget.addEventListener('input', this.phoneNumHandler);
    this.stateTarget.addEventListener('change', this.stateHandler);
    this.stateTarget.addEventListener('input', this.stateHandler);
  }

  removeEventListeners() {
    this.dobTarget.removeEventListener('blur', this.dobHandler);
    this.phoneNumTarget.removeEventListener('change', this.phoneNumHandler);
    this.phoneNumTarget.removeEventListener('input', this.phoneNumHandler);
    this.stateTarget.removeEventListener('change', this.stateHandler);
    this.stateTarget.removeEventListener('input', this.stateHandler);
  }

  validateDob() {
    if (this.dobTarget.value.length < 10) { // "MM/DD/YYYY"
      this.dobTarget.setAttribute('valid', false);
      this.disableSubmitButton();
      return;
    }

    if (this.isValidDOB()) {
      this.dobTarget.setAttribute('valid', true);
      this.enableSubmitButton();
    } else {
      this.dobTarget.setAttribute('valid', false);
      this.disableSubmitButton();
      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#set_ineligible_details', 'dob');
        },
      });
    }
  }

  validatePhoneNumber() {
    if (this.phoneNumTarget.value.length < 14) { // "(XXX) XXX-XXXX"
      this.phoneNumTarget.setAttribute('valid', false);
      this.disableSubmitButton();
    } else {
      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#validate_phone_number', this.phoneNumTarget.value);
        },
      });
    }
  }

  validateState() {
    if (!this.stateTarget.value) return;

    if (this.validStates.includes(this.stateTarget.value)) {
      this.stateTarget.setAttribute('valid', true);
      this.enableSubmitButton();
    } else {
      this.stateTarget.setAttribute('valid', false);
      this.disableSubmitButton();
      this.callStimulusAction({
        cb: () => {
          this.stimulate('UserDemographics::Ineligible#set_ineligible_details', 'state');
        },
      });
    }
  }

  isValidDOB() {
    const currentYear = moment().year();
    const birthYear = moment(this.dobTarget.value, 'MM/DD/YYYY').year();
    const age = currentYear - birthYear;
    return age >= 18 && (birthYear >= currentYear - 120);
  }

  disableSubmitButton() {
    if (this.submitTarget.hasAttribute('disabled')) return;
    if (this.allFieldsValid()) {
      this.arrowTarget.setAttribute('src', this.arrowTarget.dataset.rightArrowActive);
      return;
    }

    this.submitTarget.setAttribute('disabled', true);
    this.arrowTarget.setAttribute('alt', 'right-arrow-inactive');
    this.arrowTarget.setAttribute('src', this.arrowTarget.dataset.rightArrowInactive);
    this.arrowTarget.classList.remove('hover:cursor-pointer');
    this.arrowTarget.classList.add('hover:cursor-not-allowed');
  }

  enableSubmitButton() {
    if (!this.submitTarget.hasAttribute('disabled')) return;
    if (!this.allFieldsValid()) return;

    this.submitTarget.removeAttribute('disabled');
    this.arrowTarget.setAttribute('alt', 'right-arrow-active');
    this.arrowTarget.setAttribute('src', this.arrowTarget.dataset.rightArrowActive);
    this.arrowTarget.classList.add('hover:cursor-pointer');
    this.arrowTarget.classList.remove('hover:cursor-not-allowed');
  }

  allFieldsValid() {
    const fields = [this.dobTarget, this.phoneNumTarget, this.stateTarget];
    return fields.every((field) => field.getAttribute('valid') === 'true');
  }
}
