import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  static targets = ["fill", "currentstep", "tooltip", "success", "fail"];

  // Check if progress bar has a failure step
  HAS_FAILED = false;

  // Progress bar can be default or refill
  TYPE = $(this.currentstepTarget).data("type");

  // Steps for the regular progress bar
  PROGRESS_BAR_STEPS = {
    1: {
      title: "Order Placed",
      tooltip: "",
      display: true,
    },
    2: {
      title: "Dr Review",
      tooltip:
        "Your doctor is reviewing your medical questionnaire. Please check your secure Inbox for any messages from your doctor.",
      display: true,
    },
    3: {
      title: "Rx Approved",
      tooltip:
        "Your doctor has approved your prescription. Please check your secure Inbox for any messages from your doctor.",
      display: true,
    },
    4: {
      title: "RX Approved & Filed in Rx",
      tooltip: "",
      display: false,
    },
    5: {
      title: "Order Filled & Shipped",
      tooltip:
        "Shipping information has been sent to you. Please check your secure Inbox for any messages from your doctor.",
      display: true,
    },
    6: {
      title: "Delivered",
      tooltip:
        "Your order has been delivered. Tracking number is available on the Order History tab.",
      display: true,
    },
  };

  // Steps for the refill progress bar
  REFILL_PROGRESS_BAR_STEPS = {
    1: {
      title: "Order Processed",
      tooltip: "",
      display: true,
    },
    2: {
      title: "Rx Approved",
      tooltip:
        "Your doctor has authorized your prescription. Your medication will now be filled and shipped to you.",
      display: true,
    },
    3: {
      title: "Order Filled & Shipped",
      tooltip:
        "Your order has been shipped. Tracking number is available on the Order History tab.",
      display: true,
    },
    4: {
      title: "Delivered",
      tooltip:
        'Click Order More or Start Subscription to receive a new order."',
      display: true,
    },
  };

  // Errors that are displayed on the progress bar
  ERRORS = {
    7: {
      title: "Rx Discontinued",
      tooltip: "Your doctor has discontinued your prescription.",
    },
    8: {
      title: "Rx Declined",
      tooltip:
        "Your doctor was unable to approve your order request. Please check your secure Inbox for details. Your credit card will not be charged and your pre-authorized hold will be released.",
    },
    9: {
      title: "Not Delivered",
      tooltip:
        "Your order was not delivered. Please review your tracking number which is available on the Order History tab. For any questions or inquiries, please reach out to customer care for assistance.",
    },
    10: {
      title: "Order Cancelled",
      tooltip: "",
    },
    11: {
      title: "Payment Failed",
      tooltip: "",
    },
  };

  connect() {
    super.connect();
  }

  initialize() {
    this.setupProgressBar();
    this.initTooltips();
  }

  disconnect() {
    $(".progress-bar__step").remove();
  }

  initTooltips() {
    $(this.tooltipTargets).on("mouseenter", (event) => {
      event.preventDefault();

      if (!event.target.dataset.title.length) return;

      const tooltip = $(
        `<div class="progress-bar__tooltip">${event.target.dataset.title}</div>`
      );

      $(event.target.parentNode).append(tooltip);
    });

    $(this.tooltipTargets).on("mouseleave", (event) => {
      $(event.target.parentNode).find(".progress-bar__tooltip").remove();
    });
  }

  /*
    Create progress bar steps depending on type of progress bar
    Also adds any error state if needed.
  */
  setupProgressBar() {
    const currentstep = +$(this.currentstepTarget).val();

    let completedSteps = 0;

    const refillSteps = Array.from(
      Array(Object.keys(this.REFILL_PROGRESS_BAR_STEPS).length),
      (_, i) => i + 1
    );

    const defaultSteps = Array.from(
      Array(Object.keys(this.PROGRESS_BAR_STEPS).length),
      (_, i) => i + 1
    );

    if (this.TYPE === "default") {
      completedSteps = defaultSteps;
    } else {
      completedSteps = refillSteps;
    }

    completedSteps.forEach((step) => {
      if (this.ERRORS[currentstep]?.title === "Not Delivered" && step === 4) {
        this.appendError(currentstep);
        this.HAS_FAILED = true;
      } else if (
        (this.ERRORS[currentstep]?.title === "Order Cancelled" ||
          this.ERRORS[currentstep]?.title === "Payment Failed") &&
        step === 1
      ) {
        this.appendError(currentstep);
        this.HAS_FAILED = true;
      } else if (
        (this.ERRORS[currentstep]?.title === "Rx Declined" ||
          this.ERRORS[currentstep]?.title === "Rx Discontinued") &&
        step === 2
      ) {
        this.appendError(currentstep);
        this.HAS_FAILED = true;
      } else {
        this.addProgressBarStep(completedSteps, currentstep, step);
      }
    });
  }

  /*
    Add a step to the progress bar
    Adds blue fill to the progress bar if the step is completed
    Adds a half filled blue fill if the step is current one
  */
  addProgressBarStep(completedSteps, currentstep, step) {
    const foundStep =
      this.TYPE === "refill"
        ? this.REFILL_PROGRESS_BAR_STEPS[step]
        : this.PROGRESS_BAR_STEPS[step];

    if (!foundStep.display) return;

    const stepDiv = $(
      `<div class="progress-bar__step"><p class="progress-bar__title">${foundStep.title}</p></div>`
    );

    $(this.fillTarget).append(stepDiv);

    if (currentstep >= step && !this.HAS_FAILED) {
      const completed = $("<div></div>");

      stepDiv.append(completed);

      const isDelivered =
        (this.TYPE === "default" && step === 6) ||
        (this.TYPE === "refill" && step === 4);

      if (step === currentstep && !isDelivered) {
        completed.addClass("completed halfway");
      } else {
        completed.addClass("completed");
      }

      this.appendCheck(stepDiv, "success", step);
    }
  }

  // Appends error icon to the progress bar
  appendError(currentstep) {
    const stepDiv = $(
      `<div class="progress-bar__step"><p class="progress-bar__title">${this.ERRORS[currentstep].title}</p></div>`
    );

    $(this.fillTarget).append(stepDiv);

    const completed = $("<div></div>");

    stepDiv.append(completed);

    completed.addClass("completed halfway");

    this.appendCheck(stepDiv, "error", currentstep);
  }

  // Appends check icon to the progress bar
  appendCheck(div, type, step) {
    const image = $("<img class='progress-bar__check'>");
    const success = this.successTarget.dataset.asset;
    const fail = this.failTarget.dataset.asset;

    let foundStep =
      this.TYPE === "refill"
        ? this.REFILL_PROGRESS_BAR_STEPS[step]
        : this.PROGRESS_BAR_STEPS[step];

    if (type === "error") {
      image.attr("src", fail);
      foundStep = this.ERRORS[step];
    } else {
      image.attr("src", success);
    }

    image.attr("data-store--members-portal--progress-bar-target", "tooltip");

    $(image).attr("data-title", foundStep.tooltip);

    div.append(image);
  }
}
